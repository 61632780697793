import {Subscription} from 'rxjs';
import {isNullOrUndefined} from './object.util';

export class SubscriptionUtil {
	static unsubscribe(subscription: Array<Subscription> | Subscription) {
		if (!isNullOrUndefined(subscription)) {
			const subscriptions = Array.isArray(subscription) ? subscription : [subscription];
			subscriptions.forEach((subscription: Subscription) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
		}
	}
}
