import {Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
	selector: 'aga-button[id]',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
	@ViewChild('file') file: ElementRef;
	@Input() id: string;
	@Input() iconUri: string;
	@Input() type: 'submit' | 'reset' | 'button' | 'file' = 'button';
	@Input() accept: string; // for type 'file' only
	@Input() backgroundColor: string;
	@HostBinding('attr.color') @Input() color: 'primary' | 'secondary' = 'primary';
	@HostBinding('attr.size') @Input() size: 'large' | 'medium' = 'large';
	@HostBinding('class.disabled') @Input() disabled: boolean;
	@HostBinding('class.icon-button') isIconButton: boolean;
	@Output() fileSelect: EventEmitter<File> = new EventEmitter<File>();

	@HostListener('click', ['$event'])
	click(event: MouseEvent): void {
		if (this.type === 'file') {
			// Reset selected file so that the change event is always fired
			// even when the previously selected file is the same as the current file
			this.file.nativeElement.value = null;
			this.file.nativeElement.click();
		}
	}

	ngOnInit(): void {
		if (!this.id) {
			throw new Error(`Button attribute 'id' is required`);
		}
		this.isIconButton = !!this.iconUri;
	}
}
