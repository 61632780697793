import {cloneDeep} from 'lodash';

export const isObjectEmpty = (obj): boolean => {
	return !Object.keys(obj).length;
};

export const isNullOrUndefined = (value: any): boolean => {
	return value === null || value === undefined;
};

export const isNotNullOrUndefined = (value: any): boolean => {
	return !isNullOrUndefined(value);
};

export const filterEmptyKeys = (value: any): any => {
	const tempObject = cloneDeep(value);
	for (let property in tempObject) {
		if (isObjectEmpty(tempObject[property])) {
			delete tempObject[property];
		}
	}
	return tempObject;
};

export const removeEmptyProps = (value: any): any => {
	const tempObject = cloneDeep(value);
	for (let property in tempObject) {
		if (isNullOrUndefined(tempObject[property])) {
			delete tempObject[property];
		}
	}
	return tempObject;
};

export const setAllValuesAInObjectToB = (object: any, a: any, b: any, omitKeys: Array<string> = []) => {
	const tempObject = cloneDeep(object);
	Object.keys(tempObject).forEach(key => {
		if (!omitKeys.includes(key) && JSON.stringify(tempObject[key]) === JSON.stringify(a)) {
			tempObject[key] = b;
		}
	});
	return tempObject;
};
